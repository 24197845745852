import {graphql, useFragment, useLazyLoadQuery} from 'react-relay'
import {TemplateListNew, type TemplateListNewSelectedProp} from './TemplateListNew'
import {InfoIcon} from '@primer/octicons-react'
import {LABELS} from './constants/labels'
import {useIssueCreateConfigContext} from './contexts/IssueCreateConfigContext'
import styles from './TemplateListPane.module.css'
import type {TemplateListPaneNewQuery} from './__generated__/TemplateListPaneNewQuery.graphql'
import type {TemplateListPaneNew$key} from './__generated__/TemplateListPaneNew.graphql'

export const TemplateListNewPaneGraphQLQuery = graphql`
  query TemplateListPaneNewQuery($id: ID!) {
    node(id: $id) {
      ... on Repository {
        ...TemplateListPaneNew
      }
    }
  }
`

export type TemplateListNewPaneInternalProps = {
  repository: TemplateListPaneNew$key
} & TemplateListNewPaneSharedProps

type TemplateListNewPaneSharedProps = {
  /** Id to use on validation messages
   *
   * `TemplateListNewPane` might be used with a repository that has issues disabled or has no templates available.
   * When this is the case, a message is shown and rendered with the id passed in this property.
   *
   * Consumers can then use this id in conjuction with an `aria-describedby` to ensure their control is properly
   * described with the message and accessible.
   */
  descriptionId?: string
} & TemplateListNewSelectedProp

type TemplateListNewPaneFromRepoProps = {
  repositoryId: string
} & TemplateListNewPaneSharedProps

export function TemplateListNewPaneFromRepo({repositoryId, ...props}: TemplateListNewPaneFromRepoProps) {
  const data = useLazyLoadQuery<TemplateListPaneNewQuery>(TemplateListNewPaneGraphQLQuery, {
    id: repositoryId,
  })
  if (!data.node) return null

  return <TemplateListNewInternal repository={data.node} {...props} />
}

export function TemplateListNewInternal({repository, descriptionId, ...props}: TemplateListNewPaneInternalProps) {
  const {optionConfig} = useIssueCreateConfigContext()
  const {insidePortal} = optionConfig
  const data = useFragment(
    graphql`
      fragment TemplateListPaneNew on Repository {
        hasIssuesEnabled
        ...TemplateListNew
      }
    `,
    repository,
  )

  return (
    <div>
      {data.hasIssuesEnabled ? (
        <TemplateListNew
          repository={data}
          {...props}
          className={`${
            !insidePortal
              ? `border borderColor-muted rounded-2 overflow-hidden
                ${optionConfig.showRepositoryPicker ? 'pt-0 mt-0' : 'py-2 mt-2'}`
              : ``
          }`}
        />
      ) : (
        <div className={`${styles.disabledStateGap} d-flex flex-row flex-items-center m-3`}>
          <InfoIcon />
          <span id={descriptionId}>{LABELS.issuesDisabledForRepo}</span>
        </div>
      )}
    </div>
  )
}

try{ TemplateListNewPaneFromRepo.displayName ||= 'TemplateListNewPaneFromRepo' } catch {}
try{ TemplateListNewInternal.displayName ||= 'TemplateListNewInternal' } catch {}