import {ShieldIcon} from '@primer/octicons-react'
import {LABELS} from './constants/labels'
import {noop} from '@github-ui/noop'
import {IssueTemplateItemNew} from './IssueTemplateItemNew'

type SecurityPolicyItemProps = {
  link: string | null | undefined
}

export const SecurityPolicyRow = ({link}: SecurityPolicyItemProps): JSX.Element => {
  return (
    <IssueTemplateItemNew
      filename="default-security-policy"
      link={link}
      externalLink
      onTemplateSelected={noop}
      name={LABELS.securityPolicyName}
      about={LABELS.securityPolicyDescription}
      trailingIcon={<ShieldIcon />}
    />
  )
}

try{ SecurityPolicyRow.displayName ||= 'SecurityPolicyRow' } catch {}