import {LABELS} from './constants/labels'
import {BLANK_ISSUE, IssueCreationKind} from './utils/model'
import {IssueTemplateItemNew} from './IssueTemplateItemNew'
import {getTemplateUrl} from './utils/urls'

type BlankIssueItemProps = {
  onTemplateSelected?: (filename: string, kind: IssueCreationKind) => void
  nameWithOwner: string
}

export const BlankIssueItem = ({nameWithOwner, onTemplateSelected}: BlankIssueItemProps): JSX.Element => {
  return (
    <IssueTemplateItemNew
      filename={BLANK_ISSUE}
      onTemplateSelected={() => onTemplateSelected?.(BLANK_ISSUE, IssueCreationKind.BlankIssue)}
      name={LABELS.blankIssueName}
      about={LABELS.blankIssueDescription}
      link={onTemplateSelected ? undefined : getTemplateUrl(nameWithOwner, BLANK_ISSUE)}
    />
  )
}

try{ BlankIssueItem.displayName ||= 'BlankIssueItem' } catch {}