/**
 * @generated SignedSource<<a0c98a684fdff421eb76226e7c2937d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TemplateListPaneNew$data = {
  readonly hasIssuesEnabled: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateListNew">;
  readonly " $fragmentType": "TemplateListPaneNew";
};
export type TemplateListPaneNew$key = {
  readonly " $data"?: TemplateListPaneNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateListPaneNew">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateListPaneNew",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasIssuesEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateListNew"
    }
  ],
  "type": "Repository",
  "abstractKey": null
};

(node as any).hash = "716618aa6a4fb2cd52fd96df62585403";

export default node;
