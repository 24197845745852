/**
 * @generated SignedSource<<4a5e75b26fd122f666b993da4e323890>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CreateIssueV2$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TemplateListPaneNew" | "TemplatePickerButtonV2">;
  readonly " $fragmentType": "CreateIssueV2";
};
export type CreateIssueV2$key = {
  readonly " $data"?: CreateIssueV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateIssueV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeTemplates"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateIssueV2",
  "selections": [
    {
      "condition": "includeTemplates",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TemplatePickerButtonV2"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TemplateListPaneNew"
        }
      ]
    }
  ],
  "type": "Repository",
  "abstractKey": null
};

(node as any).hash = "95f6e4725c0a31ebc8e176331c759f97";

export default node;
