/**
 * @generated SignedSource<<b33d9467badbc5126508bfb865d5fbb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TemplatePickerButtonV2$data = {
  readonly hasAnyTemplates: boolean;
  readonly nameWithOwner: string;
  readonly " $fragmentType": "TemplatePickerButtonV2";
};
export type TemplatePickerButtonV2$key = {
  readonly " $data"?: TemplatePickerButtonV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplatePickerButtonV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplatePickerButtonV2",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameWithOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAnyTemplates",
      "storageKey": null
    }
  ],
  "type": "Repository",
  "abstractKey": null
};

(node as any).hash = "c0b7894ec33c623c05400e2205cd0e4e";

export default node;
